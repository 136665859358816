import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { colors, fontSizes, useThemeContext } from "../../structure/theme"
import Link from "../../navigation/link.component"
import UniversePicto from "../../universe/universe.picto"
import breakpoint from "styled-components-breakpoint"
import { graphql, useStaticQuery } from "gatsby"

const Container = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -24px;

  ${breakpoint(`small`)`
    padding: 0;
    margin-bottom: 45px;
  `}
`

const Text = styled.span`
  margin-left: 23px;
  font-size: ${fontSizes(`regular`)};
  font-weight: 500;
  line-height: 1.31;
  color: ${colors(`grey.g800`)};
  text-align: center;
`

const PictoContainer = styled.div`
  width: 35px;
  height: 35px;
`

const Button = styled(Link)`
  height: 60px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 6px 0;
  transition: transform 0.2s ease;
  text-decoration: none;
  color: ${colors(`grey.g800`)};
  padding: 0 17px;

  :last-of-type {
    margin-bottom: 0;
  }

  :nth-child(-n + 2) {
    padding: 0;
    margin-top: 0;
    height: 119px;
    width: calc(50% - 6px);
    flex-direction: column;
    justify-content: center;

    ${Text} {
      margin-left: 0;
      margin-top: 17px;
    }

    ${PictoContainer} {
      height: 40px;
      width: 40px;

      ${breakpoint(`small`)`
        height: 55px;
        width: 55px;
      `}
    }
  }

  :hover {
    transform: translateX(2px) translateY(2px);
  }
`

const PictoGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;

  /* stylelint-disable no-descending-specificity */
  ${PictoContainer} {
    /* stylelint-enable no-descending-specificity */
    :not(:first-of-type) {
      margin-left: -10px;
    }
  }
`

function BannerCtaPictos ({ universes, color }) {
  if (universes.length === 0) {
    return null
  }

  const pictos = universes.map(universe => (
    <PictoContainer key={universe.originalId}>
      <UniversePicto universe={universe} size="100%" color={color} />
    </PictoContainer>
  ))

  if (pictos.length === 1) {
    return pictos
  }

  return <PictoGroup>{pictos}</PictoGroup>
}

BannerCtaPictos.propTypes = {
  universes: PropTypes.arrayOf(
    PropTypes.shape({
      originalId: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  color: PropTypes.any,
}

export default function BannerCta () {
  const categoryPages = useStaticQuery(query)
  const {
    colors: { primary },
  } = useThemeContext()

  return (
    <Container>
      {Object.values(categoryPages).map(categoryPage => {
        if (!categoryPage) {
          return null
        }

        const { slug, title, universes } = categoryPage

        return (
          <Button route="category" params={categoryPage} key={slug}>
            <BannerCtaPictos universes={universes} color={slug.includes(`plomberie`) ? `#3bb9b2` : primary} />
            <Text>{title}</Text>
          </Button>
        )
      })}
    </Container>
  )
}

const query = graphql`
  fragment universeCta on CategoryPage {
    title
    slug
    universes {
      originalId
      pictoSvg
      primaryColor
    }
  }

  query {
    plomberie: categoryPage(slug: { regex: "/plomberie/" }) {
      ...universeCta
    }
    electricitegaz: categoryPage(slug: { regex: "/electricite/" }) {
      ...universeCta
    }
    electromenager: categoryPage(slug: { regex: "/electromenager/" }) {
      ...universeCta
    }
    serrurerie: categoryPage(slug: { regex: "/serrurerie/" }) {
      ...universeCta
    }
    objetsconnectes: categoryPage(slug: { regex: "/connectes/" }) {
      ...universeCta
    }
  }
`
