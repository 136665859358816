import React from "react"
import PropTypes from "prop-types"
import DynamicBlock from "../../../components/block/dynamicBlock.component"
import { useTranslation } from "../../translation/translate.component"

export default function HomeContent ({ products }) {
  const { t } = useTranslation()

  return (
    <>
      <DynamicBlock __component="OwnerRenter" />
      <DynamicBlock
        __component="ScrollerProduct"
        blockTitle={t(`common:product.scroller_title`)}
        blockTitleSeo="h2"
        products={products}
      />
      <DynamicBlock
        __component="ThreeSteps"
        data-testid="three_steps"
        blockTitle={t(`common:information.section_three_steps_title`)}
        blockTitleSeo="h2"
      />
      <DynamicBlock __component="ThreeBlockPicture" withEscdaBlock={false} />
      <DynamicBlock __component="Warranties" data-testid="warranties_block" />
    </>
  )
}

HomeContent.propTypes = {
  products: PropTypes.array.isRequired,
}
