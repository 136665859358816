import styled from "styled-components"
import { condition, fontSizes } from "../../structure/theme"
import breakpoint from "styled-components-breakpoint"

export const HomeBannerTitle = styled.h1`
  color: white;
  text-align: center;
  font-size: ${fontSizes(`large`)};
  font-weight: 500;
  line-height: 1.21;
  margin-top: 30px;
  margin-bottom: 18px;
  width: 100%;

  b,
  strong {
    display: block;
    font-weight: 900;
    font-size: ${fontSizes(`xx_large`)};
    line-height: 1.21;
    margin-bottom: 10px;
  }

  ${breakpoint(`small`)`
    text-align: ${condition(`textCenter`, `center`, `left`)};
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
    font-size: ${fontSizes(`x_large`)};
    
    strong {
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      font-size: ${fontSizes(`xxx_large`)};
    }
  `}
`
