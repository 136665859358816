import React, { memo } from "react"
import { Helmet } from "react-helmet"
import { inlineTemplateString } from "../../../utils/format"

function OfferCodeMaskRedirect () {
  const fadeDelay = 500
  const message = ``

  const style = inlineTemplateString(`
    @keyframes dot {
      33% {
        content: '${message}.. ';
      }
      66% {
        content: '${message}...';
      }
    }
    
    body::after {
      content: '${message}.';
      background-color: #FFF;
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 4000;
      align-items: center;
      justify-content: center;
      opacity: 1;
      transition: opacity ${fadeDelay}ms;
      animation: dot 1s linear 0s infinite;
      font-family: ${APP_CONFIG.theme.fonts.primary.family};
      font-size: ${APP_CONFIG.theme.fonts.sizes.xxx_large};
    }
    
    body:active::after {
      animation: none;
    }
  `)

  const fadeOutStyle = inlineTemplateString(`
    body::after {
      opacity: 0;
    }
  `)

  return (
    <Helmet>
      <script type="text/javascript">
        {`
          function injectStyle (style) {
            const styleNode = document.createElement("style");
            styleNode.appendChild(document.createTextNode(style));
            document.head.appendChild(styleNode);

            return styleNode;
          }
            
          if (/(\\?|&)code=\\w+/.test(document.location.search)) {
            const node = injectStyle("${style}");
            
            window.closeOfferCodeMask = function () {              
              const fadeOutNode = injectStyle("${fadeOutStyle}");

              setTimeout(function () {
                document.head.removeChild(node);
                document.head.removeChild(fadeOutNode);
              }, ${fadeDelay});
            }
          }
        `}
      </script>
    </Helmet>
  )
}

export default memo(OfferCodeMaskRedirect)
