import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { useProductImpressionTracking, useViewPageTracking } from "../thirdParties/gtm/tagManager"
import OfferCodeMaskRedirect from "./home/offerCodeMask.component"
import SeoSchema from "../seo/seoSchema.component"
import HomeContent from "./home/homeContent.component"
import HomeBanner from "./home/homeBanner.component"
import { useTranslation } from "../translation/translate.component"
import { useWebsiteConfiguration } from "../website/useWebsiteConfiguration.hook"
import DynamicBlock from "../../components/block/dynamicBlock.component"
import { useThemeContext } from "../structure/theme"

export default function HomePage ({
  data: {
    websiteHomepage: { bannerText, products },
    headerImage,
    allInterventionExample,
    allWebsiteTrustedPartner,
  },
}) {
  const { t } = useTranslation()
  const { salesPhone } = useWebsiteConfiguration()
  const theme = useThemeContext()
  useViewPageTracking(`Homepage`, ``, `Homepage`)
  useProductImpressionTracking(products, `Homepage`)

  return (
    <>
      <SeoSchema name="organization" telephone={salesPhone} />
      <OfferCodeMaskRedirect />
      <HomeBanner image={headerImage} text={bannerText} />
      <DynamicBlock __component="OfferCodeSection" blockAnchor="promo-zone" />
      <DynamicBlock
        __component="VerifiedReviews"
        blockTitle={t(`common:landing.section_verified_reviews`)}
        blockTitleSeo="h2"
      />
      <DynamicBlock
        __component="InterventionExample"
        data-testid="intervention_example_block"
        blockTitle={t(`common:navigation.block_exemple_title`)}
        blockTitleSeo="h2"
        interventionExamples={allInterventionExample.nodes}
      />
      <HomeContent products={products} />
      <DynamicBlock
        __component="WebsiteTrustedPartners"
        blockTitle={t(`common:website.section_trusted_partner_title`)}
        blockTitleStyle="small"
        blockBgColor={theme.colors.grey.g200}
        blockBgWidth="full"
        websiteTrustedPartners={allWebsiteTrustedPartner.nodes}
      />
    </>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    headerImage: PropTypes.object.isRequired,
    websiteHomepage: PropTypes.shape({
      bannerText: PropTypes.string.isRequired,
      products: PropTypes.array.isRequired,
    }).isRequired,
    allInterventionExample: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
    allWebsiteTrustedPartner: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query WebsiteHomepage($id: String!) {
    headerImage: fileImageWithOverride(name: "homepage") {
      ...responsiveFileImage
    }
    websiteHomepage {
      bannerText
      products {
        ...productByUniverse
      }
    }
    allInterventionExample(
      filter: { page: { id: { eq: $id } }, description: { ne: "" }, link: { ne: "" } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        title
        description
        link
        linkText
      }
    }
    allWebsiteTrustedPartner {
      nodes {
        id
        url
        logo {
          file {
            childImageSharp {
              fixed(height: 70, trim: 10) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
