import React from "react"
import PropTypes from "prop-types"
import Banner from "../banner.component"
import BannerCta from "./bannerCta.component"
import { useThemeContext } from "../../structure/theme"
import { HomeBannerTitle } from "./homeBannerTitle.component"
import { HomeBannerBevel } from "./homeBannerBevel.component"

export default function HomeBanner ({ image, text }) {
  const {
    colors: { primary },
  } = useThemeContext()

  return (
    <HomeBannerBevel>
      <Banner img={image} bannerBgColor={primary} hideImageForResponsive bevel>
        <HomeBannerTitle
          textCenter
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        <BannerCta />
      </Banner>
    </HomeBannerBevel>
  )
}

HomeBanner.propTypes = {
  image: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
}
