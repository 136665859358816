import styled from "styled-components"
import { colors } from "../../structure/theme"
import breakpoint from "styled-components-breakpoint"

export const HomeBannerBevel = styled.div`
  position: relative;
  margin-bottom: 40px;
  width: 100%;

  /* bevel */
  :after {
    content: "";
    position: absolute;
    bottom: -18px;
    right: 0;
    display: inline-block;
    height: 40px;
    width: 150%;
    background-color: ${colors(`primary`)};
    transform-origin: top right;
    transform: skewY(-2deg);
  }

  ${breakpoint(`small`)`    
    margin-bottom: 0;
    
    :after {
      display: none;
    }
  `}
`
